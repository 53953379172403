import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
    meta: {
      auth: {
        roles: 'User'
      }
    }
  },
  {
    path: '/history',
    name: 'History',
    component: () => import(/* webpackChunkName: "history" */ '@/views/History.vue'),
    meta: {
      title: 'History',
      auth: {
        roles: 'User'
      }
    }
  },
  {
    path: '/user/login',
    name: 'UserLogin',
    component: () => import(/* webpackChunkName: "user-login" */ '@/views/user/Login.vue'),
    meta: {
      title: 'Login',
      // TODO: makes redirect to /404 page if user already authorized, need to add /404 page
      auth: false
    }
  },
  {
    path: '/user/set-password',
    name: 'UserSetPassword',
    component: () => import(/* webpackChunkName: "user-set-password" */ '@/views/user/SetPassword.vue'),
    meta: {
      title: 'Set password',
      auth: true
    }
  },
  {
    path: '/user/update-password',
    name: 'UserUpdatePassword',
    component: () => import(/* webpackChunkName: "user-update-password" */ '@/views/user/UpdatePassword.vue'),
    meta: {
      title: 'Update password',
      auth: true
    }
  },
  {
    path: '/user/update-login',
    name: 'UserUpdateLogin',
    component: () => import(/* webpackChunkName: "user-update-login" */ '@/views/user/UpdateLogin.vue'),
    meta: {
      title: 'Update login',
      auth: true
    }
  },
  {
    path: '/user/balance',
    name: 'UserBalance',
    component: () => import(/* webpackChunkName: "user-balance" */ '@/views/user/Balance.vue'),
    meta: {
      title: 'Balance',
      auth: true
    }
  },
  {
    path: '/admin/users',
    name: 'AdminUsers',
    component: () => import(/* webpackChunkName: "admin-users" */ '@/views/admin/Users.vue'),
    meta: {
      title: 'Users',
      auth: {
        roles: 'Admin'
      }
    }
  },
  {
    path: '/admin/config',
    name: 'AdminConfig',
    component: () => import(/* webpackChunkName: "admin-config" */ '@/views/admin/Config.vue'),
    meta: {
      title: 'Update config',
      auth: {
        roles: 'Admin'
      }
    }
  },
  {
    path: '/admin/cards',
    name: 'AdminCards',
    component: () => import(/* webpackChunkName: "admin-cards" */ '@/views/admin/Cards.vue'),
    meta: {
      title: 'Cards',
      auth: {
        roles: 'Admin'
      }
    }
  },
  {
    path: '/admin/autocomplete',
    name: 'AdminAutocomplete',
    component: () => import(/* webpackChunkName: "admin-autocomplete" */ '@/views/admin/Autocomplete.vue'),
    meta: {
      title: 'Autocomplete',
      auth: {
        roles: 'Admin'
      }
    }
  },
  {
    path: '/admin/balance',
    name: 'AdminBalance',
    component: () => import(/* webpackChunkName: "admin-balance" */ '@/views/admin/Balance.vue'),
    meta: {
      title: 'Balance',
      auth: {
        roles: 'Admin'
      }
    }
  },
  {
    path: '/403',
    name: '403',
    component: () => import(/* webpackChunkName: "403" */ '@/views/403.vue'),
    meta: {
      title: 'Forbidden'
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue'),
    meta: {
      title: 'Page not found'
    }
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue'),
    meta: {
      title: 'Page not found'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  exact: true,
  linkExactActiveClass: 'active'
})

router.beforeEach((to, from, next) => {
  const setPasswordRouteName = 'UserSetPassword'

  if (Vue.auth.user() == null && Vue.auth.remember() !== null) {
    try {
      Vue.auth.user(JSON.parse(Vue.auth.remember()))
    } catch {
      Vue.auth.token(Vue.auth.options.refreshTokenKey, null)
      Vue.auth.unremember()
      Vue.auth.logout()
    }
  }

  if (Vue.auth.user()) {
    if (Vue.auth.user().passIsTemp && to.name !== setPasswordRouteName) {
      next({ name: setPasswordRouteName })
    } else {
      if (to.name === 'Home' && Vue.auth.check('Admin')) {
        next({ name: 'AdminCards' })
      } else {
        next()
      }
    }
  } else {
    next()
  }
})

export default router
