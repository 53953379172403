<template>
  <div id="app">
    <template v-if="!isMobile">
      <b-navbar
        toggleable="lg"
        type="dark"
        variant="dark"
        class="mb-3"
      >
        <b-navbar-brand :to="{ name: $auth.check('Admin') ? 'AdminCards' : 'Home' }">
          Toloka
          <b-badge
            v-if="!$auth.check('Admin') && $store.state.cardsCount !== null"
            class="mr-1"
            title="Cards available count"
          >
            {{ $store.state.cardsCount }}
          </b-badge>
          <b-badge
            v-if="!$auth.check('Admin') && $store.state.quorumConfig !== null"
            variant="info"
            class="mr-1"
            title="Quorum config"
          >
            {{ $store.state.quorumConfig }}
          </b-badge>
          <b-badge
            v-if="!$auth.check('Admin') && $store.state.balance !== null"
            variant="success"
            title="Balance"
            class="mr-1"
            :to="{ name: 'UserBalance' }"
          >
            $ {{ $store.state.balance }}
          </b-badge>
          <b-badge
            v-if="!$auth.check('Admin') && $store.state.presenceCounter !== null"
            title="Presence counter"
          >
            {{ $store.state.presenceCounter }}
          </b-badge>
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse" />

        <b-collapse
          id="nav-collapse"
          is-nav
        >
          <b-navbar-nav>
            <b-nav-item
              v-if="$auth.check('Admin')"
              :to="{ name: 'AdminUsers' }"
            >
              Users
            </b-nav-item>
            <b-nav-item
              v-if="$auth.check('Admin')"
              :to="{ name: 'AdminCards' }"
            >
              Cards
            </b-nav-item>
            <b-nav-item
              v-if="$auth.check('Admin')"
              :to="{ name: 'AdminConfig' }"
            >
              Config
            </b-nav-item>
            <b-nav-item
              v-if="$auth.check('Admin')"
              :to="{ name: 'AdminAutocomplete' }"
            >
              Autocomplete
            </b-nav-item>
            <b-nav-item
              v-if="$auth.check('Admin')"
              :to="{ name: 'AdminBalance' }"
            >
              Balance
            </b-nav-item>
            <b-nav-item
              v-if="$auth.check('User')"
              :to="{ name: 'Home' }"
            >
              Cards
            </b-nav-item>
            <b-nav-item
              v-if="$auth.check('User')"
              :to="{ name: 'History' }"
            >
              History
            </b-nav-item>
          </b-navbar-nav>

          <b-navbar-nav class="ml-auto">
            <b-nav-item
              v-if="!$auth.check()"
              :to="{ name: 'UserLogin' }"
            >
              Sign in
            </b-nav-item>
            <b-nav-item-dropdown
              v-else
              right
            >
              <template #button-content>
                <b-icon icon="person" />
              </template>
              <b-dropdown-item :to="{ name: 'UserUpdateLogin' }">
                Update login
              </b-dropdown-item>
              <b-dropdown-item :to="{ name: 'UserUpdatePassword' }">
                Update password
              </b-dropdown-item>
              <b-dropdown-item @click="logout()">
                Logout
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>

      <b-container fluid>
        <router-view v-if="$auth.ready()" />
        <b-spinner v-else />
      </b-container>
    </template>
    <template v-else>
      <b-container>
        <b-alert
          class="mt-4"
          :show="true"
          variant="danger">
          Работа с мобильных устройств больше не поддерживается
        </b-alert>
      </b-container>
    </template>
  </div>
</template>

<script>
export default {
  name: 'App',
  computed: {
    isMobile () {
      return false
      // if (!this.$auth.user()) {
      //   return false
      // }
      // if (this.$auth.check('Admin')) {
      //   return false
      // }
      // return 'ontouchstart' in document.documentElement
    }
  },
  created () {
    this.$auth.load()
      .then(() => {
        if (this.$auth.user()) {
          this.$store.dispatch('initWs', this.$auth.user().wsToken)
        }
      })
  },
  methods: {
    logout () {
      this.$auth.token(this.$auth.options.refreshTokenKey, null)
      this.$auth.unremember()
      this.$auth.logout({})
      this.$store.dispatch('closeWs')
    }
  }
}
</script>

<style>
table.table thead th {
  vertical-align: middle;
}
</style>
