import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import auth from '@websanova/vue-auth'
import VuePageTitle from 'vue-page-title'
import VueDebounce from 'vue-debounce'
import App from './App.vue'
import router from './router'
import VueRouterPlugin from '@/plugins/vue-router'
import store from './store'
import AuthConfig from './auth.config'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

axios.defaults.baseURL = process.env.VUE_APP_URL

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueAxios, axios)
Vue.use(VueRouterPlugin, router)
Vue.use(auth, AuthConfig)
Vue.use(VuePageTitle, {
  suffix: '- Toloka',
  router
})
Vue.use(VueDebounce)

Vue.config.productionTip = false

const app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

store.$app = app
