import AuthAxios from '@websanova/vue-auth/dist/drivers/http/axios.1.x.esm'
import AuthRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm'

function getExpiresAt (validInMs) {
  return new Date(new Date().getTime() + validInMs).getTime()
}

export default {
  auth: {
    request: function (req, token) {
      if (req.url === this.options.refreshData.url) {
        req.data = {
          uuid: this.user().uuid,
          refreshToken: this.token(this.options.refreshTokenKey)
        }
      } else {
        this.options.http.setHeaders.call(this, req, {
          Authorization: 'Bearer ' + token
        })
      }
    },
    response: function (res) {
      if ([this.options.loginData.url, this.options.refreshData.url].includes(res.config.url)) {
        if (res.status === 200) {
          const user = {
            ...res.data,
            expiresAt: getExpiresAt(res.data.validInMs)
          }

          if (res.config.url === this.options.refreshData.url) {
            this.user(user)
          }
          this.remember(JSON.stringify(user))

          this.token(this.options.refreshTokenKey, res.data.refreshToken, false)

          return res.data.token
        }
      }

      if (res.status === 401) {
        this.token(this.options.refreshTokenKey, null)
        this.unremember()

        // redirect for not login/refresh request
        if (!this.redirect()) {
          this.router.routerGo.call(this, this.options.logoutData.redirect)
        }
      }
    }
  },
  http: AuthAxios,
  router: AuthRouter,
  tokenDefaultKey: 'auth_token',
  refreshTokenKey: 'refresh_token',
  rolesKey: 'role',
  authRedirect: {
    name: 'UserLogin'
  },
  loginData: {
    url: '/user/login',
    fetchUser: false
  },
  refreshData: {
    url: '/user/token/refresh',
    method: 'POST',
    enabled: false
  },
  fetchData: {
    enabled: false
  },
  logoutData: {
    redirect: {
      name: 'UserLogin'
    }
  },
  setPasswordRedirect: {
    name: 'UserSetPassword'
  },
  parseUserData: (data) => {
    if (data) {
      return {
        ...data,
        expiresAt: getExpiresAt(data.validInMs)
      }
    }

    return {}
  }
}
