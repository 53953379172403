import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ws: null,
    cardsCount: null,
    quorumConfig: null,
    balance: null,
    presenceCounter: null,
    cardTitleIsHidden: null
  },
  mutations: {
    setWs (state, payload) {
      state.ws = payload
    },
    setCardsCount (state, payload) {
      state.cardsCount = payload
    },
    reduceCardsCount (state) {
      if (state.cardsCount !== null && state.cardsCount > 0) {
        state.cardsCount--
      }
    },
    setQuorumConfig (state, payload) {
      state.quorumConfig = payload
    },
    setBalance (state, payload) {
      state.balance = payload
    },
    updatePresenceCounter (state, payload) {
      if (payload) {
        state.presenceCounter = `${payload.votesCount}/${payload.numClosedCards}/${payload.cardsThreshold}`
      }
    },
    setTitlesHiddenState (state, payload) {
      state.cardTitleIsHidden = payload
    }
  },
  actions: {
    initWs ({
      state,
      commit,
      dispatch,
      getters
    }, wsToken) {
      dispatch('closeWs')

      commit('setWs', new WebSocket(process.env.VUE_APP_WS_URL + '?token=' + wsToken))

      state.ws.onmessage = event => {
        dispatch('handleWsMessage', event.data)
      }
    },
    closeWs ({
      state,
      commit
    }) {
      if (state.ws && state.ws.readyState !== 3) {
        state.ws.close()
      }

      commit('setCardsCount', null)
      commit('setQuorumConfig', null)
      commit('setBalance', null)
      commit('updatePresenceCounter', null)
      commit('setTitlesHiddenState', null)
    },
    handleWsMessage ({ commit }, dataEncoded) {
      const data = JSON.parse(dataEncoded)

      switch (data.type) {
        case 'Init':
          commit('setCardsCount', data.payload.cardsCount)
          commit('setQuorumConfig', data.payload.quorumConfig)
          commit('setBalance', Number(data.payload.balance))
          commit('updatePresenceCounter', data.payload.presenceCount)
          commit('setTitlesHiddenState', data.payload.cardTitleIsHidden)
          break

        case 'CardsCount':
          commit('setCardsCount', data.payload)
          break

        case 'Balance':
          commit('setBalance', Number(data.payload))
          break

        case 'QuorumConfig':
          commit('setQuorumConfig', data.payload)
          break

        case 'Votes':
          this.$app.$emit('Votes', data.payload)
          break

        case 'CardClosed':
          if (this.$app.$auth.check('User')) {
            this.$app.$emit('CardClosed', Number(data.payload))
          } else {
            this.$app.$emit('CardClosed', data.payload)
          }
          break

        case 'PresenceRecalculated':
          if (this.$app.$auth.check('User')) {
            commit('updatePresenceCounter', data.payload)
          } else {
            this.$app.$emit('PresenceRecalculated', data.payload)
          }
          break
      }
    }
  },
  modules: {}
})
